import { colors } from '../../styles/colors';
import { pricingMenu } from './menu';
const Pricing = () => {
  return (
    <div
      className="flex pb-5  flex-col justify-start items-center
    w-full h-full relative overflow-hidden bg-white"
      id="pricing"
    >
      <div
        className="absolute xl:w-[1360px] xl:h-[870px] lg:w-[977px]  xs:h-[1390px] 
      lg:h-[834px] md:w-[720px] md:h-[785px] xs:w-full md:rounded-[40px] xs:rounded-none bg-[#f5f6f7]"
      />

      <div
        data-aos="fade-up"
        className="flex flex-col justify-start items-center 
       xl:px-[155px] lg:px-[100px] md:px-[24px] top-[110px] py-10 gap-10 z-20"
      >
        <div
          data-aos="fade-up"
          className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-4"
        >
          <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
            <p className="flex-grow-0 flex-shrink-0 xl:text-[48px] lg:text-[40px] md:text-[32px] xs:text-[24px]  font-medium text-left text-[#0c062c]">
              Blueprints for Success
            </p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex justify-center xl:w-[1130px] xl:py-14 
             lg:w-[890px] lg:py-10 lg:px-10  
             md:w-[676px] md:py-8 md:px-6   
             xs:w-[330px] xs:py-5 xs:px-4
             gap-6 rounded-[40px] bg-[#b349ed]/10 backdrop-blur-2xl 
             
             md:flex-row xs:flex-col items-stretch 
               xs:gap-8"
        >
          {pricingMenu.map(
            (
              {
                title,
                price,
                period,
                description,
                options,
                bestSeller,
                shortOptions,
              },
              idx
            ) => (
              <div
                key={idx}
                className={`flex flex-col justify-between bg-white rounded-3xl 
                     xl:w-[500px] lg:w-[400px] md:w-[300px] xs:w-[294px] 
                     p-8 lg:p-8 md:p-6 xs:p-4 gap-10 border-2 ${
                       bestSeller ? 'border-[#a72dea]' : 'border-white'
                     }`}
              >
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col items-start xl:gap-4 lg:gap-2 ">
                    <h3
                      className="text-[#1a0d60] xl:text-[40px] lg:text-[32px] md:text-[28px] xs:text-[24px]
                   font-medium font-['Outfit'] leading-[48px]"
                    >
                      {title}
                    </h3>

                    <div className="flex items-end">
                      <span
                        className="text-[#1a0d60] lg:text-[28px] md:text-[24px] xs:text-[20px]
                       font-medium font-['Outfit'] leading-9"
                      >
                        ${price}
                      </span>
                      <span
                        className="opacity-70 text-[#1a0d60] lg:text-[20px] md:text-[18px] xs:text-[14px]
                      font-normal font-['Outfit'] leading-normal"
                      >
                        /{period}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-[#0c062c]/80 lg:text-[16px] md:text-[16px] xs:text-[14px] font-medium font-['Outfit'] leading-relaxed">
                      {description}
                    </p>
                    {shortOptions.length > 0 && (
                      <div className="flex md:flex-row flex-col gap-4 md:mb-4 ">
                        {shortOptions.map((option) => (
                          <div className="flex justify-start items-center gap-2 flex-row">
                            <span className="w-3 h-3 bg-[#CF84F3] rounded-full flex-shrink-0">
                              <img
                                className=""
                                src="/assets/icons/pricing-okay.svg"
                              />
                            </span>
                            <div className="text-[#0c062c]/80 lg:text-[16px] md:text-[14px] xs:text-[14px] font-normal font-['Outfit'] leading-[25.20px]">
                              {option}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <ul className="flex flex-col gap-2">
                      {options.map((option, idx) => (
                        <li key={idx} className="flex items-center gap-2">
                          <span className="w-3 h-3 bg-[#CF84F3] rounded-full flex-shrink-0">
                            <img
                              className=""
                              src="/assets/icons/pricing-okay.svg"
                            />
                          </span>
                          <span
                            className="text-start text-[#0c062c]/80  lg:text-[16px] md:text-[14px] xs:text-[14px]
                           font-normal font-['Outfit'] leading-[25.20px]"
                          >
                            {option}
                          </span>
                        </li>
                      ))}
                    </ul>{' '}
                  </div>
                </div>

                <div className="w-full mt-4">
                  <a
                    className={`w-full h-14 px-7 rounded-full shadow-inner 
                          border ${
                            bestSeller
                              ? 'border-[#9b17e4] bg-gradient-to-r from-[#714bdb] via-[#8553ec] to-[#af48e9]'
                              : 'border-[#9b17e4] bg-white'
                          } flex justify-center items-center cursor-pointer`}
                    href="https://platform.launchify.ai/register"
                    target="_blank"
                  >
                    <span
                      className={`text-center xl:text-lg font-medium font-['Outfit'] leading-snug ${
                        bestSeller ? 'text-white' : 'text-[#9b17e4]'
                      }`}
                    >
                      Sign up
                    </span>
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default Pricing;
