import { useState } from 'react';
import { defaultQuestions } from './menu';

const FAQ = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null
  ); // null indicates no question is open

  const toggleQuestion = (index: number) => {
    if (openQuestionIndex === index) {
      // If the clicked question is already open, close it
      setOpenQuestionIndex(null);
    } else {
      // Otherwise, open the clicked question
      setOpenQuestionIndex(index);
    }
  };
  return (
    <div
      className="flex xl:pt-[120px] lg:pt-[80px] md:pt-[40px] xs:pt-[20px] xl:pb-[80px] lg:pb-[80px] md:pb-[120px] xs:pb-[116px] flex-col justify-start items-center
    w-full h-full relative overflow-hidden bg-white"
    >
      <div className="flex flex-col justify-start items-center xl:gap-20 lg:gap-16 md:gap-8 xs:gap-8 w-full">
        <div
          data-aos="fade-up"
          className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 md:gap-16 xs:gap-10 w-full"
        >
          <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-4">
            <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <p className="flex-grow-0 flex-shrink-0 md:text-xl xs:text-16 font-medium text-left uppercase text-[#a72dea]">
                FAQ
              </p>
              <p className="flex-grow-0 flex-shrink-0 md:text-5xl xs:text-24 font-medium text-left text-[#0c062c]">
                Any questions
              </p>
            </div>
          </div>
          <div
            className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 xl:w-[902px] lg:w-[810px] md:w-[656px] 
          xs:w-[90%] gap-4"
          >
            {defaultQuestions.map(({ content, answer }, index) => (
              <div
                key={index}
                className="flex flex-col justify-start items-start self-stretch flex-grow-0  cursor-pointer 
                flex-shrink-0 relative gap-6 px-8 py-7 rounded-3xl bg-[#6839ee]/[0.08] hover:bg-[#6839EE]/[0.24]"
              >
                <div
                  onClick={() => toggleQuestion(index)}
                  className="flex justify-between items-center self-stretch flex-grow-0 flex-shrink-0 gap-2 relative"
                >
                  <p className="flex-grow-0 md:flex-shrink-0 md:text-xl xs:text-20 text-left text-[#1a0d60] font-medium">
                    {content}
                  </p>

                  {openQuestionIndex === index ? (
                    // Minus SVG
                    <svg
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-8 h-8 relative md:h-[32px] md:w-[32px]  xs:h-[24px] xs:w-[24px]"
                    >
                      <path
                        d="M8 16H24"
                        stroke="#0C062C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    // Plus SVG
                    <svg
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-grow-0 flex-shrink-0 w-8 h-8 relative md:h-[32px] md:w-[32px]  xs:h-[24px] xs:w-[24px]"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        d="M8 16H24"
                        stroke="#0C062C"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 8V24"
                        stroke="#0C062C"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                {openQuestionIndex === index && (
                  <p className="flex-grow-0 flex-shrink-0  md:text-xl xs:text-[16px] text-left text-[#0c062c]/80 font-[400]">
                    {answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-10 md:mt-0 xs:mt-[80px]"
        >
          <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-4">
            <p className="flex-grow-0 flex-shrink-0 xl:text-[28px] lg:text-[24px] md:text-[18px] font-medium text-left text-[#0c062c]">
              Still have questions?
            </p>
            <p className="flex-grow-0 flex-shrink-0 md:w-[589px]  sm:w-[358px] xs:w-[326px] xl:text-[18px] lg:text-[16px] md:text-[16px] text-center text-[#0c062c]/80">
              Book a call with us here and one of your dedicated experts will
              reach out to you within 24 hours
            </p>
          </div>
          <a
            className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-14 
            relative gap-2.5 px-7 pt-[18px] pb-5 rounded-[40px] xs:w-full md:w-auto free-trial-btn cursor-pointer"
            href="https://platform.launchify.ai/register"
            target="_blank"
          >
            <p className=" xs:uppercase md:normal-case flex-grow-0 flex-shrink-0 xl:text-[18px] lg:text-[16px] xs:text-[16px] font-medium text-center text-white">
              Book a demo
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
