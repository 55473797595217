import BusinessTypeMenuItem from '../../pages/BusinessModels/components/BusinessTypeMenuItem';
// menu.ts
export type BusinessType = 'POD' | 'Dropshipping' | 'Branded';

interface IBusinessTypeSelector {
  businessTypes: BusinessType[];
  selectedType: BusinessType;
  setSelectedType: (value: BusinessType) => void;
}
const BusinessTypeSelector = ({
  businessTypes,
  selectedType,
  setSelectedType,
}: IBusinessTypeSelector) => {
  return (
    <div
      id="business-types"
      data-aos="fade-up"
      className="xl:mt-[80px] lg:mt-[64px] md:mt-[40px] xs:mt-[40px] 
        xl:text-18 lg:text-18 md:text-16 xs:text-14 
        flex justify-start items-center md:pl-2 md:pr-2 md:py-2 
        xs:py-[4px] xs:pl-[4px] xs:pr-[16px] 
        md:rounded-2xl xs:rounded-[6px] bg-[#eff1f4] 
        sm:flex xs:px-4"
    >
      {businessTypes.map((type) => (
        <BusinessTypeMenuItem
          key={type}
          label={
            type === 'POD'
              ? 'Print on Demand'
              : type === 'Branded'
              ? 'Branded Business'
              : 'Dropshipping'
          }
          shortLabel={type === 'POD' ? 'POD' : undefined}
          isSelected={selectedType === type}
          onClick={() => setSelectedType(type)}
          isActive={false}
        />
      ))}
    </div>
  );
};

export default BusinessTypeSelector;
