import designWithAIAsset from '../../assets/pages/journey/design-with-ai.gif';
import launchAtStore from '../../assets/pages/journey/launch-at-store.png';
import enjoyAutomaticManufacturing from '../../assets/pages/journey/enjoy-automatic-manufacturing.png';

import horizontalDesignWithAIAsset from '../../assets/pages/journey/h_design-with-ai.gif';
import horizontalLaunchAtStore from '../../assets/pages/journey/h_launch-at-store.png';
import horizontalEnjoyAutomaticManufacturing from '../../assets/pages/journey/h_enjoy-automatic-manufacturing.png';

import horizontalAliexpress from '../../assets/pages/journey/dropshipping/h_aliexpress_1.gif';
import horizontalProductDetails from '../../assets/pages/journey/dropshipping/h_product_details_2.gif';
import horizontalStatistics from '../../assets/pages/journey/dropshipping/h_statistics_3.png';

import widthAliexpress from '../../assets/pages/journey/dropshipping/w_aliexpress_1.gif';
import widthProductDetails from '../../assets/pages/journey/dropshipping/w_product_details_2.gif';
import widthStatistics from '../../assets/pages/journey/dropshipping/w_statistics_3.gif';

import brandedDesktop1 from '../../assets/pages/journey/branded/desktop/1.png';
import brandedDesktop2 from '../../assets/pages/journey/branded/desktop/2.png';
import brandedDesktop3 from '../../assets/pages/journey/branded/desktop/3.png';
import brandedMobile1 from '../../assets/pages/journey/branded/mobile/1.png';
import brandedMobile2 from '../../assets/pages/journey/branded/mobile/2.png';
import brandedMobile3 from '../../assets/pages/journey/branded/mobile/3.png';

import dropshippingDesktop1 from '../../assets/pages/journey/dropshipping/desktop/1.gif';
import dropshippingDesktop2 from '../../assets/pages/journey/dropshipping/desktop/2.gif';
import dropshippingDesktop3 from '../../assets/pages/journey/dropshipping/desktop/3.gif';
import dropshippingMobile1 from '../../assets/pages/journey/dropshipping/mobile/1.gif';
import dropshippingMobile2 from '../../assets/pages/journey/dropshipping/mobile/2.gif';
import dropshippingMobile3 from '../../assets/pages/journey/dropshipping/mobile/3.gif';

import { BusinessType } from '../BusinessModels/menu';

export interface CardItem {
  title: string;
  description: string;
  image: any;
  horizontalImage: any;
}
export const podCards = [
  {
    title:
      'Create your own online store and POD product designs using AI in minutes ',
    description:
      'Pick your favourite products from t-shirts, hoodies, bags, mugs, and more... Effortlessly apply AI-powered designs or your custom designs to a wide range of products in just a few clicks',
    image: designWithAIAsset,
    horizontalImage: horizontalDesignWithAIAsset,
  },
  {
    title: 'Launch on Amazon, Shopify, and Etsy all at the same time ',
    description:
      'Pick your favourite products from t-shirts, hoodies, bags, mugs, and more... Effortlessly apply AI-powered designs or your custom designs to a wide range of products in just a few clicks',
    image: launchAtStore,
    horizontalImage: horizontalLaunchAtStore,
  },
  {
    title: 'Enjoy automatic manufacturing, shipping, and customer fulfilment ',
    description:
      'Experience seamless and hands-free operations with automatic manufacturing, shipping, and customer fulfilment. Let Launchify take care of the entire process, so you can focus on growing your business',
    image: enjoyAutomaticManufacturing,
    horizontalImage: horizontalEnjoyAutomaticManufacturing,
  },
];
export const dropshippingCards = [
  {
    title: 'Choose Your Products ',
    description:
      'Begin your dropshipping journey by selecting a diverse range of products directly from AliExpress. Our easy navigation and search tools help you quickly identify high-demand products to attract your target market.',
    image: dropshippingDesktop1,
    horizontalImage: dropshippingMobile1,
  },
  {
    title: 'Import, Customize and Publish Products',
    description:
      'Efficiently import your chosen products to your store with our automated tools. Customize product details, adjust images, and set robust pricing rules to ensure profitability. ',
    image: dropshippingDesktop2,
    horizontalImage: dropshippingMobile2,
  },
  {
    title: 'Manage Orders and Customer Relationships',
    description: `Take control of your store's operations with streamlined order management. Automate order processing, track shipments, and handle customer inquiries all from one platform.`,
    image: dropshippingDesktop3,
    horizontalImage: dropshippingMobile3,
  },
];
export const brandedBusinessCards = [
  {
    title: 'Choose Your Products ',
    description:
      'Pick your favorite products from coffee, supplements, beauty, and more... Effortlessly apply AI-powered designs or your custom designs to a wide range of products in just a few clicks',
    image: brandedDesktop1,
    horizontalImage: brandedMobile1,
  },
  {
    title: 'Import, Customize and Publish Products',
    description:
      'Efficiently import your chosen products to your store with our automated tools. Customize product details, adjust images, and set robust pricing rules to ensure profitability. ',
    image: brandedDesktop2,
    horizontalImage: brandedMobile2,
  },
  {
    title: 'Enjoy automatic manufacturing, shipping, and customer fulfilment',
    description: `Launch multiple products in just 60 seconds without the hassle of shipping. Our platform automates order processing, tracks shipments, and lets you focus on growing your business while we handle logistics.`,
    image: brandedDesktop3,
    horizontalImage: brandedMobile3,
  },
];

export const businessSelectCards: Record<BusinessType, CardItem[]> = {
  POD: podCards,
  Dropshipping: dropshippingCards,
  Branded: brandedBusinessCards,
};
