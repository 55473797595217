import { ReactComponent as InstagramAsset } from '../../assets/socials/instagram.svg';
import { ReactComponent as FacebookAsset } from '../../assets/socials/facebook.svg';
import { ReactComponent as LinkeInAsset } from '../../assets/socials/linkeIn.svg';

export const socials = [
  {
    title: 'Instagram',
    Icon: InstagramAsset,
    href: 'https://www.instagram.com/launchify.ai',
  },

  {
    title: 'LinkeIn',
    Icon: LinkeInAsset,
    href: 'https://www.linkedin.com/company/launchifyai',
  },
  {
    title: 'Facebook',
    Icon: FacebookAsset,
    href: 'https://www.facebook.com/launchify.ai',
  },
];
