import './App.css';

import AOS from 'aos'; // Ensure AOS is imported
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from './router';
import Footer from './pages/Home/Footer';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });

    AOS.refresh();
  }, []);
  // Using the useRoutes hook to handle routing based on the defined routes
  const content = useRoutes(routes());
  return (
    <div className="App font-outfit  flex flex-col overflow-y-auto overflow-x-hidden bg-white">
      <ToastContainer toastStyle={{ width: 'fit-content' }} />

      <div className="App font-outfit">{content}</div>
      
    </div>
  );
}

export default App;
